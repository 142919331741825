import React, { useEffect, useState } from 'react';

import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@atlaskit/modal-dialog';
import { Col, Row } from 'react-bootstrap';
import { BsFillLightningFill } from 'react-icons/bs';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import {
  InputMoney,
  InputNumber,
  InputSelect,
  InputText,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import api from '~/services/api';
import { useManutencaoFiscal } from '../../ManutencaoFiscalContext';
import { SelectProps } from '../../protocols/SelectProps';

export const ModalEditNFItem: React.FC = () => {
  const [cfopsPerfil, setCfopsPerfil] = useState<SelectProps[]>([]);

  const {
    openModal,
    handleOpenModal,
    gtinsAtivos,
    tributacoesSelect,
    ncms,
    getValues,
    getValuesFiscal,
    handleAplicarItens,
    registerFiscal,
    controlFiscal,
    setValueFiscal,
    formStateFiscal,
  } = useManutencaoFiscal();

  useEffect(() => {
    const getCfops = async () => {
      const codPerfil = getValues('cod_perfil') as number;

      const { data } = await api.get(`/perfil-documento/${codPerfil}`);
      const c: Array<{ cfop: string; cod_perfil: number; cod_id_ctb: number }> =
        data.data.cfops;

      const cfopsFiltrados: SelectProps[] = c.map((cfop) => ({
        label: cfop.cfop,
        value: Number(cfop.cfop),
      }));

      setCfopsPerfil(cfopsFiltrados);
    };

    getCfops();
  }, [getValues]);

  return (
    <>
      {openModal && (
        <Modal key="modal" width="x-large">
          <ModalHeader
            style={{
              paddingBottom: '0.5rem',
            }}
          >
            <ModalTitle>Editar Item</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <h3>{getValuesFiscal('descricao')}</h3>
            <Row style={{ alignItems: 'flex-end' }}>
              <Col md={3} sm={12}>
                <InputSelect
                  label="GTIN"
                  placeholder="Selecione..."
                  name="cod_gtin"
                  disabled={false}
                  register={registerFiscal}
                  options={gtinsAtivos}
                  isError={!!formStateFiscal.errors.cod_gtin}
                  control={controlFiscal}
                  changeSelected={(selected: any) => {
                    setValueFiscal('cod_gtin', selected);
                  }}
                />
              </Col>
              <Col md={2} sm={12}>
                <InputSelect
                  label="CFOP"
                  placeholder="Selecione..."
                  name="cfop"
                  disabled={false}
                  register={registerFiscal}
                  options={cfopsPerfil}
                  isError={!!formStateFiscal.errors.cfop}
                  control={controlFiscal}
                  changeSelected={(selected: any) => {
                    setValueFiscal('cfop', selected);
                  }}
                  onClick={() => {
                    setValueFiscal('cfop', getValuesFiscal('cfop'));
                  }}
                />
              </Col>
              <Col md={7} sm={12}>
                <InputText
                  label="Descrição"
                  min={0}
                  step={1}
                  placeholder="Informe o numero de série"
                  name="descricao"
                  disabled={false}
                  toLowerCase={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.descricao}
                  maxLength={100}
                />
              </Col>
            </Row>

            <Row style={{ alignItems: 'flex-end' }}>
              <Col md={6} sm={12}>
                <div>
                  <InputSelect
                    label="Tributação"
                    placeholder="Selecione..."
                    name="tributacao"
                    disabled={false}
                    listaHeight="200px"
                    register={registerFiscal}
                    options={tributacoesSelect}
                    isError={!!formStateFiscal.errors.tributacao}
                    control={controlFiscal}
                    changeSelected={(selected: any) => {
                      setValueFiscal('tributacao', selected);
                    }}
                    onClick={(ev: any) => {
                      const { placeholder } = ev.target;
                      const tributacao = tributacoesSelect.find(
                        (t) => t.label === placeholder,
                      );
                      setValueFiscal('tributacao', tributacao);
                    }}
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <InputSelect
                  label="NCM"
                  maxLength={50}
                  placeholder="Selecione..."
                  name="num_ncm"
                  listaHeight="200px"
                  disabled={false}
                  register={registerFiscal}
                  options={ncms}
                  isError={!!formStateFiscal.errors.num_ncm}
                  control={controlFiscal}
                  changeSelected={(selected: any) => {
                    setValueFiscal('num_ncm', selected);
                  }}
                  onClick={(ev: any) => {
                    const { placeholder } = ev.target;
                    const ncm = ncms.find((n) => n.label === placeholder);
                    setValueFiscal('num_ncm', ncm);
                  }}
                />
              </Col>
            </Row>
            <Row style={{ alignItems: 'flex-end' }}>
              <Col style={{ maxWidth: '90px' }} md={2} sm={12}>
                <InputText
                  label="Embalagem"
                  min={0}
                  step={1}
                  placeholder="Informe o numero de série"
                  name="des_embalagem"
                  disabled={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.des_embalagem}
                  maxLength={0}
                />
              </Col>
              <Col md={2} sm={12}>
                <InputNumber
                  label="Qtd. Emb."
                  min={0}
                  max={999}
                  placeholder="Informe o numero de série"
                  name="qtd_embalagem"
                  disabled={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.qtd_embalagem}
                  maxLength={0}
                />
              </Col>
              <Col md={2} sm={12}>
                <InputNumber
                  label="Qtd. Entrada"
                  min={0}
                  max={999}
                  placeholder="Informe o numero de série"
                  name="qtd_entrada"
                  disabled={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.qtd_entrada}
                  maxLength={0}
                />
              </Col>
              <Col md={2} sm={12}>
                <InputNumber
                  label="Qtd Total"
                  min={0}
                  max={999}
                  step="1"
                  placeholder="Informe o numero de série"
                  name="qtd_total"
                  disabled={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.qtd_total}
                  maxLength={0}
                />
              </Col>
              <Col md={2} sm={12}>
                <InputMoney
                  label="Val. Total:"
                  min={0}
                  placeholder="Informe o numero de série"
                  name="val_total"
                  showIcon={false}
                  disabled={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.val_total}
                />
              </Col>
              <Col style={{ maxWidth: '100px' }} md={2} sm={12}>
                <InputMoney
                  label="Desconto:"
                  min={0}
                  placeholder="Informe o numero de série"
                  name="val_desconto"
                  disabled={false}
                  showIcon={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.val_desconto}
                />
              </Col>
              <Col style={{ maxWidth: '108px' }} md={2} sm={12}>
                <InputMoney
                  label="Acréscimo:"
                  min={0}
                  placeholder="Informe o valor do acrescimo"
                  name="acrescimo"
                  disabled={false}
                  showIcon={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.acrescimo}
                />
              </Col>
            </Row>
            <Row>
              <Col md={2} sm={12}>
                <InputMoney
                  label="BC ICMS OP:"
                  min={0}
                  placeholder="Informe o numero de série"
                  name="val_bc_icms"
                  disabled={false}
                  showIcon={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.val_bc_icms}
                />
              </Col>
              <Col md={2} sm={12}>
                <InputMoney
                  label="ICMS OP:"
                  min={0}
                  placeholder="Informe o numero de série"
                  name="val_icms_op"
                  showIcon={false}
                  disabled={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.val_icms_op}
                />
              </Col>
              <Col style={{ maxWidth: '125px' }} md={2} sm={12}>
                <InputMoney
                  label="Outros:"
                  min={0}
                  placeholder="Informe o numero de série"
                  name="outros"
                  showIcon={false}
                  disabled={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.outros}
                />
              </Col>
              <Col md={2} sm={12}>
                <InputMoney
                  label="Isento:"
                  min={0}
                  placeholder="Informe o numero de série"
                  name="val_isento"
                  showIcon={false}
                  disabled={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.val_isento}
                />
              </Col>
              <div
                style={{
                  width: ' 0px',
                  height: '50px',
                  marginTop: '20px',
                }}
              >
                <div
                  style={{
                    width: '1px',
                    height: '50px',
                    background: '#dfe1e6',
                  }}
                >
                  {' '}
                </div>
              </div>

              <Col md={2} sm={12}>
                <InputMoney
                  label="BC ST:"
                  min={0}
                  placeholder="Informe o numero de série"
                  name="val_bc_st"
                  disabled={false}
                  showIcon={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.val_bc_st}
                />
              </Col>
              <Col md={2} sm={12}>
                <InputMoney
                  label="ST:"
                  min={0}
                  placeholder="Informe o numero de série"
                  name="st"
                  disabled={false}
                  showIcon={false}
                  register={registerFiscal}
                  isError={!!formStateFiscal.errors.st}
                />
              </Col>
            </Row>
            <Separator labelText="" />
            <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
              <Col style={{ height: '10px' }} md={6} sm={12}>
                {' '}
              </Col>
              <Col
                style={{
                  display: 'flex',
                  paddingLeft: '10px',
                  justifyContent: 'flex-end',
                }}
                md={6}
                sm={12}
              >
                <CancelButton
                  onClick={() => {
                    handleOpenModal(false);
                  }}
                >
                  CANCELAR
                </CancelButton>
                <ConfirmButton
                  onClick={() => {
                    handleAplicarItens();
                  }}
                >
                  <BsFillLightningFill fontSize="20px" color="#fff" />
                  APLICAR
                </ConfirmButton>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
