import { ItemNFProps } from '../../protocols/ItemNFProps';
import { TributacoesProps } from '../../protocols/TributacoesProps';

export interface IProduto {
  cod_tributacao: string;
}

export function CorrigirCfopItens(
  itens: ItemNFProps[],
  tributacoes: TributacoesProps[],
): ItemNFProps[] {
  const itensNF = itens;

  for (let i = 0; i < itensNF.length; i++) {
    const item = itens[i];

    const cliTributacao = tributacoes.find(
      (itemTributacao: TributacoesProps) =>
        itensNF[i].cod_tributacao === itemTributacao.cod_tributacao,
    );

    if (cliTributacao) {
      if (
        cliTributacao.tipo_tributacao === 3 ||
        cliTributacao.tipo_tributacao === 0
      ) {
        switch (Number(item.cfop)) {
          case 1102:
            itensNF[i].cfop = 1403;
            break;
          case 1403:
            itensNF[i].cfop = 1102;
            break;
          case 2102:
            itensNF[i].cfop = 2403;
            break;
          case 2403:
            itensNF[i].cfop = 2102;
            break;
          case 5102:
            itensNF[i].cfop = 5405;
            break;
          case 5405:
            itensNF[i].cfop = 5102;
            break;
          case 5152:
            itensNF[i].cfop = 5409;
            break;
          case 5409:
            itensNF[i].cfop = 5152;
            break;
          case 5202:
            itensNF[i].cfop = 5411;
            break;
          case 5411:
            itensNF[i].cfop = 5202;
            break;
          case 1202:
            itensNF[i].cfop = 1411;
            break;
          case 1411:
            itensNF[i].cfop = 1202;
            break;
          case 1152:
            itensNF[i].cfop = 1409;
            break;
          case 1409:
            itensNF[i].cfop = 1152;
            break;
          default:
            break;
        }
      }
    }
  }
  return itensNF;
}
