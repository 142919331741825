import { transformAsCurrency } from '~/utils/functions';
import { ItemNFProps } from '../../protocols/ItemNFProps';
import { TributacoesProps } from '../../protocols/TributacoesProps';
import { toast } from 'react-toastify';

export interface IProduto {
  cod_tributacao: string;
}

export function RecalculaValores(
  itens: ItemNFProps[],
  itensAnt: ItemNFProps[],
  tributacoes: TributacoesProps[],
  flg_complementar: boolean,
  uf: string,
): ItemNFProps[] {
  let count = 0;

  const calculo = itens.map((item: any, index: number) => {
    item.val_total = transformAsCurrency(item.val_total);
    item.val_bc_icms = transformAsCurrency(item.val_bc_icms);

    itensAnt[index].val_bc_icms = transformAsCurrency(
      itensAnt[index].val_bc_icms,
    );

    if (
      item.cod_tributacao !== itensAnt[index].cod_tributacao ||
      item.val_bc_icms !== itensAnt[index].val_bc_icms
    ) {
      const cliTributacao = tributacoes.find(
        (tributacao: TributacoesProps) =>
          tributacao.cod_tributacao === item.cod_tributacao,
      );

      if (cliTributacao) {
        switch (cliTributacao.tipo_tributacao) {
          case 1:
          case 8:
            item.val_isento = item.val_total;
            item.val_bc_icms = 0.0;
            break;
          case 2:
            item.val_isento = item.val_total - item.val_bc_icms;
            break;
          default:
            // eslint-disable-next-line no-case-declarations
            let estado;
            if (uf === null) {
              estado = '';
            } else {
              estado = uf.toUpperCase();
            }

            if (estado !== 'RJ' && !flg_complementar) {
              item.val_bc_icms = 0.0;
              item.val_icms = 0.0;
              item.val_isento = 0.0;
            }
            if (cliTributacao.tipo_tributacao === item.tipo_tributacao) {
              item.val_outras = item.val_total;
            }
            break;
        }
      }
    } else {
      // Caso tenha o valor icms seja igual ao anterior ele incrementa 1 no count
      // eslint-disable-next-line operator-assignment
      count = count + 1;
    }
    return item;
  });

  if (count < itens.length) {
    toast.success('Processamento concluído');
  }
  return calculo;
}
